import React, { useState } from "react";
import SideBar from "../SideBar/SideBar";
import ChartACamera from "../Chart/ChartA-Camera";
import ChartBCamera from "../Chart/ChartB-Camera";
import ChartBCameraTotal from "../Chart/ChartB-Camera-Total";
import SectionTotalPersonsTable from "../Table/SectionTotalPersonsTable";
import styles from "./Main.module.css";
import ChartAUnique from "../Chart/ChartA-Unique";
import ChartBUnique from "../Chart/ChartB-Unique";
import TotalDetailCheckTable from "../Table/TotalDetailCheckTable";
import FileUpload from "../FileUpload/FileUpload"; // FileUpload 컴포넌트 임포트

const Main = () => {
    const [selectedCharts, setSelectedCharts] = useState('Graph-Camera');
    const [selectedFile, setSelectedFile] = useState(null);

    const handleSelect = (itemId) => {
        setSelectedCharts(itemId);
    };

    const handleFileSelect = (file) => {
        console.log("Selected file:", file);
        setSelectedFile(file); // 선택된 파일 상태 업데이트
    };

    return (
        <>
            <div className={styles.headerContainer}>
                {selectedCharts === 'Graph-Camera' && (<h1 className={styles.dashboardTitle}>전체 통계</h1>)}
                {selectedCharts === 'Table-Data-Detail' && (<h1 className={styles.dashboardTitle}>일일 상세 데이터</h1>)}
                {selectedCharts === 'Graph-Unique' && (<h1 className={styles.dashboardTitle}>전체 통계(구)</h1>)}
                {selectedCharts === 'Table' && (<h1 className={styles.dashboardTitle}>섹션별 통계(구)</h1>)}
                <div className={styles.buttonGroup}>
                    {/* 새로고침 버튼 */}
                    <button className={styles.refreshButton} onClick={() => window.location.reload()}>새로고침</button>
                    
                    {/* 파일 업로드 컴포넌트 (사이드바에서 '일일 상세 데이터'를 클릭했을때 만 업로드 버튼이 보이도록 수정)*/}
                    {selectedCharts === 'Table-Data-Detail' &&  (<FileUpload onFileSelect={handleFileSelect} />)}
                </div>
            </div>
            <div className={styles.mainContainer}>
                <SideBar onSelect={handleSelect} />
                <div className={styles.contentContainer}>
                    {selectedCharts === 'Graph-Camera' && (
                        <div className={styles.charts}>
                            <div className={styles.chartSection}>
                                <h2>일자별 누적 통계</h2>
                                <ChartACamera />
                            </div>
                            <div className={styles.chartSection}>
                                <h2>일일 시간별 데이터 비교</h2>
                                <ChartBCamera />
                            </div>
                            <div className={styles.chartSection}>
                                <h2>일일 시간별 누적 데이터 비교</h2>
                                <ChartBCameraTotal />
                            </div>
                        </div>
                    )}
                    {selectedCharts === 'Table-Data-Detail' && (
                        <div className={styles.charts}>
                            <div className={styles.chartSection}>
                                <h2>일일 데이터 집계</h2>
                                <TotalDetailCheckTable />
                                {/* 파일이 선택되었을 경우 파일 이름 표시 */}
                                {selectedFile && (
                                    <p className={styles.fileName}>업로드된 파일: {selectedFile.name}</p>
                                )}
                            </div>
                        </div>
                    )}
                    {selectedCharts === 'Graph-Unique' && (
                        <div className={styles.charts}>
                            <div className={styles.chartSection}>
                                <h2>전체 집계</h2>
                                <ChartAUnique />
                            </div>
                            <div className={styles.chartSection}>
                                <h2>당일 시간별 집계</h2>
                                <ChartBUnique />
                            </div>
                        </div>
                    )}
                    {selectedCharts === 'Table' && (
                        <div className={styles.tables}>
                            <div className={styles.tableSection}>
                                <h2>섹션 별 누적 방문 인원 수</h2>
                                <SectionTotalPersonsTable />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Main;
