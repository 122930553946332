import React from 'react';
import ReactDOM from 'react-dom/client';  // React 18 방식의 렌더링
import './index.css';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';  // 성능 측정 도구

// React 18의 새로운 root 렌더링 방식
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// 성능 측정 도구 (옵션)
reportWebVitals(console.log);  // 웹 성능 측정을 콘솔에 기록
