import React from "react";
import { uploadFile } from "../Api/Api"; // 파일 업로드 API 함수 임포트
import styles from "./FileUpload.module.css"; // CSS 모듈 임포트

const FileUpload = ({ onFileSelect }) => {

    const handleFileSelect = async (event) => {
        const file = event.target.files[0];
        onFileSelect(file); // 부모 컴포넌트에 선택된 파일 전달
    
        // 서버로 파일 전송 (axios 사용)
        try {
            const response = await uploadFile(file); // uploadFile 함수 호출 (axios 사용)
            console.log("File upload response:", response); // 응답을 로그로 출력
            alert("파일 업로드가 성공적으로 완료되었습니다.");
        } catch (error) {
            console.error("파일 업로드 중 오류 발생:", error);
            alert("파일 업로드에 실패했습니다.");
        }
    };

    const handleUploadClick = () => {
        // 파일 업로드 창 열기
        document.getElementById('fileInput').click(); // 파일 업로드 창 자동으로 열기
    };

    return (
        <div>
            <label className={styles.uploadButton} onClick={handleUploadClick}>
                업로드
            </label>

            {/* 파일 입력 필드를 숨긴 상태로 구현, 클릭 시에만 파일 창이 뜸 */}
            <input
                type="file"
                id="fileInput"
                className={styles.hiddenFileInput}  // input 숨기기
                onChange={handleFileSelect}
                style={{ display: "none" }} // 처음에는 숨겨진 상태
            />
        </div>
    );
};

export default FileUpload;
