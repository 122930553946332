import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ClipLoader from "react-spinners/ClipLoader"; // 스피너 추가
import styles from "./Chart.module.css";
import {getTotalPersonsDailyUnique } from "../Api/Api";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import "chartjs-adapter-date-fns";
import ChartDataLabels from "chartjs-plugin-datalabels";  // Datalabels 플러그인 불러오기

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels  // Datalabels 플러그인 등록
);

const ChartAUnique = () => {
  // 오늘 날짜를 기본값으로 설정
  // const today = new Date(); // 현재 날짜
  const [data, setData] = useState([]); // 차트에 표시할 데이터를 관리
  const [loading, setLoading] = useState(true); // 데이터를 가져오는 동안 로딩 상태를 관리
  const [startDate, setStartDate] = useState(new Date("2024-09-28")); // 시작 날짜 상태
  const [endDate, setEndDate] = useState(new Date("2024-10-20")); // 종료 날짜 상태

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const start = startDate.toISOString().split("T")[0];
        const end = endDate.toISOString().split("T")[0];
        const result = await getTotalPersonsDailyUnique(start, end);
        console.log("ChartA API result:", result);

        // 데이터가 있고 total_persons_daily 배열이 존재하는 경우
      if (result && result.data && result.data.total_persons_daily && result.data.total_persons_daily.length > 0) {
        setData(result.data.total_persons_daily);  // total_persons_daily 데이터를 설정
      } else {
        console.log("Invalid chartA response format.");
        setData([]); // 데이터가 없을 경우 빈 배열 처리
      }

        if (startDate > endDate) {
          alert("시작날짜는 종료날짜보다 이전이어야 합니다.");
          return;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setData([]); // 에러 발생 시 빈 배열 처리
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [startDate, endDate]);

  if (loading) {
    return (
      <div className={styles.spinnerContainer}>
        <ClipLoader size={50} color={"#123abc"} loading={loading} />
      </div>
    );
  }

  const chartData = {
    labels: data.map(item => item._id), // 날짜를 그대로 사용 (포맷은 서버에서 처리된 상태)
    datasets: [
      {
        label: "날짜별 인원 수",
        data: data.map(item => item.total),
        borderColor: "rgba(210, 160, 229, 1)", // 선 색상
        backgroundColor: "rgba(210, 160, 229, 0.2)", // 채우기 색상
        borderWidth: 2, // 선 굵기
        pointBackgroundColor: "rgba(210, 160, 229, 0.2)", // 데이터 포인트의 배경색
        pointBorderColor: "rgba(210, 160, 229, 0.2)", // 데이터 포인트의 테두리 색상
        pointHoverBackgroundColor: "#fff", // 포인트 호버 시 배경색
        pointHoverBorderColor: "rgba(210, 160, 229, 1)" // 포인트 호버 시 테두리 색상
      }
    ]
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          font: {
            size: 14,
            family: "Arial",
            color: "#000"
          }
        }
      },
      tooltip: {
        enabled: true,
        backgroundColor: "rgba(0,0,0,0.7)",
        titleColor: "#fff",
        bodyColor: "#fff",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1
      },
      datalabels: {  // Datalabels 플러그인 설정
        display: true,
        align: 'top',  // 데이터 레이블 위치 설정
        color: '#000',  // 레이블 색상
        font: {
          size: 12,
          weight: 'bold'
        },
        formatter: (value) => {
          return value;  // 데이터 값을 그대로 출력
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: true,
          color: "rgba(200, 200, 200, 0.3)"
        },
        ticks: {
          color: "#000",
          font: {
            size: 12
          }
        }
      },
      y: {
        grid: {
          display: true,
          color: "rgba(200, 200, 200, 0.3)"
        },
        ticks: {
          color: "#000",
          font: {
            size: 12
          }
        }
      }
    }
  };
  

  return (
    <div className={styles.chartContainer}>
      <div className={`${styles.datePickerContainer} ${styles.chart}`}>
        <div>
          <label>시작 날짜: </label>
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            dateFormat="yyyy-MM-dd"
          />
        </div>
        <div>
          <label>종료 날짜: </label>
          <DatePicker
            selected={endDate}
            onChange={date => setEndDate(date)}
            dateFormat="yyyy-MM-dd"
          />
        </div>
      </div>
      <Line data={chartData} options={chartOptions} className={styles.chart} />
    </div>
  );
};

export default ChartAUnique;
