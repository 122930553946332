import React, { useEffect, useState } from "react";
// import { getSectionTotalPersons } from "../Api/Api";
import { getSectionTotalPersonsUnique } from "../Api/Api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./Table.module.css";

const SectionTotalPersonsTable = () => {
    // const today = new Date(); // 현재 날짜
    const [data, setData] = useState([]);  // 데이터를 상태로 관리
    const [startDate, setStartDate] = useState(new Date("2024-09-28"));  // 시작 날짜
    const [endDate, setEndDate] = useState(new Date("2024-10-20"));  // 종료 날짜

    useEffect(() => {
        const fetchData = async () => {
            try {
                // API로부터 데이터를 가져옴
                const result = await getSectionTotalPersonsUnique(
                    startDate.toISOString().split("T")[0],  // 시작 날짜 포맷
                    endDate.toISOString().split("T")[0]  // 종료 날짜 포맷
                );

                // API 응답이 정상적이고 데이터가 있는지 확인
                if (result && Array.isArray(result) && result.length > 0) {
                    setData(result);  // 데이터를 상태에 저장
                } else {
                    console.error("Invalid response format or no data available");
                    setData([]);  // 유효하지 않은 응답이거나 데이터가 없을 경우 빈 배열로 설정
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setData([]);  // 에러 발생 시 데이터 초기화
            }
        };

        fetchData();  // 날짜가 변경될 때마다 데이터를 가져옴
    }, [startDate, endDate]);

    return (
        <div className={styles.tableContainer}>
            {/* 날짜 선택용 DatePicker */}
            <div className={styles.datePickerContainer_01}>
                <div>
                    <label>시작 날짜: </label>
                    <DatePicker
                        selected={startDate}  // 선택된 시작 날짜
                        onChange={(date) => setStartDate(date)}  // 날짜 변경 시 업데이트
                        dateFormat="yyyy-MM-dd"
                    />
                </div>
                <div>
                    <label>종료 날짜: </label>
                    <DatePicker
                        selected={endDate}  // 선택된 종료 날짜
                        onChange={(date) => setEndDate(date)}  // 날짜 변경 시 업데이트
                        dateFormat="yyyy-MM-dd"
                    />
                </div>
            </div>

            {/* 섹션별 누적 방문 인원 수를 보여주는 테이블 */}
            <table className={styles.tables}>
                <thead>
                    <tr>
                        <th>번호</th>
                        <th>섹션</th>
                        <th>누적 방문 인원 수</th>
                    </tr>
                </thead>
                <tbody>
                    {/* 데이터를 순회하면서 테이블 행을 생성 */}
                    {data.length > 0 ? (
                        data.map((section, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{section._id}</td>  {/* 섹션 이름 */}
                                <td>{section.total}명</td>  {/* 누적 방문 인원 수 */}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="3">데이터가 없습니다.</td>  {/* 데이터가 없을 때 출력 */}
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default SectionTotalPersonsTable;
