import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Login from '../Login/Login';
import Main from '../Main/Main';

function App() {
  return (
    // Router: 애플리케이션의 라우팅 시스템을 설정하고 관리합니다.
    // Routes: 여러 Route를 그룹으로 묶어 URL에 따라 알맞은 컴포넌트를 선택하여 렌더링합니다.
    // Route: 특정 경로(URL)에 대해 어떤 컴포넌트를 렌더링할지 정의합니다. <Route path="/" element={<Login />} />
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
      </Routes>
    </Router>
  );
}

export default App;
