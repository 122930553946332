import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ClipLoader from "react-spinners/ClipLoader";  // 스피너 추가
import styles from "./Chart.module.css"
import {getHourlyPersonsUnique } from "../Api/Api";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import ChartDataLabels from "chartjs-plugin-datalabels";  // Datalabels 플러그인 불러오기

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    ChartDataLabels
);

const ChartBUnique = () => {
    // 오늘 날짜를 기본값으로 설정
    const today = new Date(); // 현재 날짜
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState(today); 
    useEffect(() => {
        const fetchData = async () => {
            try {
                const formattedDate = selectedDate.toISOString().split("T")[0];
                console.log("Fetching data for date:", formattedDate); // 이 로그가 먼저 찍혀야 함
                const result = await getHourlyPersonsUnique(formattedDate);
                console.log("ChartB API result:", result); // API 결과를 확인
    
                
                    // 응답 형식에 맞게 result.data.hourly_persons 사용
                if (result && result.data && result.data.hourly_persons) {
                    console.log("hourly_persons array length:", result.data.hourly_persons.length);
                    if (result.data.hourly_persons.length > 0) {
                        setData(result.data.hourly_persons);
                    } else {
                        console.log("No data found.");
                        setData([]);
                    }
                } else {
                    console.log("Invalid chartB response format.");
                    setData([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setData([]);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [selectedDate]);
       

    if (loading) {
        return (
            <div className={styles.spinnerContainer}>
                <ClipLoader size={50} color={"#123abc"} loading={loading} />
            </div>
        );
    }

    const chartData = {
        labels: data.length > 0 ? data.map(item => `${item._id.hour}:00`) : ["No data"], // 데이터 없을 시 기본 라벨
        datasets: [
            {
                label: '시간별 인원',
                data: data.length > 0 ? data.map(item => item.total) : [0], // 데이터 없을 시 기본 데이터
                borderColor: 'rgba(247, 145, 35, 1)', // 선 색상
                backgroundColor: 'rgba(247, 145, 35, 0.2)', // 채우기 색상
                borderWidth: 2, // 선 굵기
                pointBackgroundColor: 'rgba(247, 145, 35, 0.2)', // 데이터 포인트의 배경색
                pointBorderColor: 'rgba(247, 145, 35, 0.2)', // 데이터 포인트의 테두리 색상
                pointHoverBackgroundColor: '#fff', // 포인트 호버 시 배경색
                pointHoverBorderColor: 'rgba(210, 160, 229, 1)' // 포인트 호버 시 테두리 색상
            }
        ]
        
    };
    

    console.log("Chart labels:", data.map(item => `${item._id.hour}:00`));
    console.log("Chart data:", data.map(item => item.total));
    
    const options = {
        scales: {
            x: {
                type: 'category',
                time: {
                    unit: 'hour',
                    tooltipFormat: 'HH:mm',
                    displayFormats: {
                        hour: 'HH:mm'
                    }
                }
            },
            y: {
                beginAtZero: true
            }
        },
        plugins: {
            datalabels: {
                display: true,
                align: 'top', // 데이터 라벨의 위치를 'top'으로 설정
                color: '#000', // 데이터 라벨 색상
                font: {
                    size: 12,
                    weight: 'bold'
                },
                formatter: (value) => value  // 데이터 값을 그대로 표시
            }
        }
    };
    

    return (
        <div className={styles.chartContainer}>
            <div className={`${styles.datePickerContainer} ${styles.chart}`}>
                <div>
                    <label>조회기간: </label>
                    <DatePicker
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        dateFormat="yyyy-MM-dd"
                    />
                </div>
                <div></div>
                <Line data={chartData} options={options} />
            </div>
        </div>
    );
};

export default ChartBUnique;
