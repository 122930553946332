import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { max } from 'lodash';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ClipLoader from "react-spinners/ClipLoader";
import styles from "./Chart.module.css";
import { getHourlyPersonsCamera } from "../Api/Api";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    ChartDataLabels
);

const ChartBCamera = () => {
    const today = new Date("2024-10-21");
    const todayBefore = new Date();
    todayBefore.setDate(today.getDate() - 1);
    const todayBeforeTwo = new Date();
    todayBeforeTwo.setDate(today.getDate() - 2);
    const todayBeforeThree = new Date();
    todayBeforeThree.setDate(today.getDate() - 3);
    const todayBeforeFour = new Date();
    todayBeforeFour.setDate(today.getDate() - 4);

    const [selectedDateA, setSelectedDateA] = useState(todayBeforeFour);  // A: 12일
    const [selectedDateB, setSelectedDateB] = useState(todayBeforeThree); // B: 13일
    const [selectedDateC, setSelectedDateC] = useState(todayBeforeTwo);   // C: 14일
    const [selectedDateD, setSelectedDateD] = useState(todayBefore);      // D: 15일

    const [dataA, setDataA] = useState([]);
    const [dataB, setDataB] = useState([]);
    const [dataC, setDataC] = useState([]);
    const [dataD, setDataD] = useState([]);

    const [previousDateA, setPreviousDateA] = useState(todayBeforeFour);  // 이전 A 날짜 저장
    const [previousDateB, setPreviousDateB] = useState(todayBeforeThree); // 이전 B 날짜 저장
    const [previousDateC, setPreviousDateC] = useState(todayBefore); // 이전 B 날짜 저장
    const [previousDateD, setPreviousDateD] = useState(today); // 이전 B 날짜 저장

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            try {
                const formattedDateA = selectedDateA.toISOString().split("T")[0];
                const formattedDateB = selectedDateB.toISOString().split("T")[0];
                const formattedDateC = selectedDateC.toISOString().split("T")[0];
                const formattedDateD = selectedDateD.toISOString().split("T")[0];

                // 예외 처리 1: 두 날짜가 같을 때
                const formattedDates = [formattedDateA, formattedDateB, formattedDateC, formattedDateD];
                const uniqueDates = new Set(formattedDates); // 중복된 날짜가 없으면 Set의 크기는 4
                if (uniqueDates.size < formattedDates.length) {
                    alert("선택된 날짜 중 서로 같은 날짜가 있을 수 없습니다.");
                    // 이전 날짜로 복구
                    setSelectedDateA(previousDateA);
                    setSelectedDateB(previousDateB);
                    setSelectedDateC(previousDateC);
                    setSelectedDateD(previousDateD);
                    setLoading(false);
                    return;
                }

                // 예외 처리 2: 오늘 날짜가 선택되었을 때
                const todayDateString = new Date().toISOString().split("T")[0];
                if (formattedDates.some(date => date === todayDateString)) { //some() : 배열에서 주어진 조건을 만족하는 요소가 하나라도 있으면 ture 반환
                    alert("오늘 날짜는 선택할 수 없습니다.");
                    setSelectedDateA(previousDateA);  // 이전 날짜로 복구
                    setSelectedDateB(previousDateB);  // 이전 날짜로 복구
                    setSelectedDateC(previousDateC);
                    setSelectedDateD(previousDateD);
                    setLoading(false);
                    return;
                }

                // 예외 처리 3: 내일 이후 날짜가 선택되었을 때
                const tomorrow = new Date();
                tomorrow.setDate(tomorrow.getDate() + 1);
                const tomorrowDateString = tomorrow.toISOString().split("T")[0];
                if (formattedDates.some(date => date >= tomorrowDateString)) {
                    alert("내일 이후의 날짜는 선택할 수 없습니다.");
                    setSelectedDateA(previousDateA);  // 이전 날짜로 복구
                    setSelectedDateB(previousDateB);  // 이전 날짜로 복구
                    setSelectedDateC(previousDateC);
                    setSelectedDateD(previousDateD);
                    setLoading(false);
                    return;
                }

                // 데이터 가져오기
                const resultA = await getHourlyPersonsCamera(formattedDateA);
                const resultB = await getHourlyPersonsCamera(formattedDateB);
                const resultC = await getHourlyPersonsCamera(formattedDateC);
                const resultD = await getHourlyPersonsCamera(formattedDateD);
                

                // 정상적인 데이터가 있을 경우에만 날짜 상태를 업데이트
                setPreviousDateA(selectedDateA);
                setPreviousDateB(selectedDateB);
                setPreviousDateC(selectedDateC);
                setPreviousDateD(selectedDateD);
                setDataA(resultA.data || []);
                setDataB(resultB.data || []);
                setDataC(resultC.data || []);
                setDataD(resultD.data || []);
            } catch (error) {
                console.error("Error fetching data:", error);
                setDataA([]);
                setDataB([]);
                setDataC([]);
                setDataD([]);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDateA, selectedDateB, selectedDateC, selectedDateD]);

    if (loading) {
        return (
            <div className={styles.spinnerContainer}>
                <ClipLoader size={50} color={"#123abc"} loading={loading} />
            </div>
        );
    }

    // 차트 데이터 구성
    const chartData = {
        labels: dataA.length > 0 ? dataA.map(item => item.hour) : ["No data"],
        datasets: [
            {
                label: `${selectedDateA.toISOString().split("T")[0]}`, // A 날짜로 label 설정
                data: dataA.length > 0 ? dataA.map(item => item.hourly_attendees) : [0],
                borderColor: 'rgba(247, 145, 35, 1)',
                backgroundColor: 'rgba(247, 145, 35, 0.2)', // 채우기 색상
                borderWidth: 2,
                pointBackgroundColor: 'rgba(255, 255, 255, 1)',
                pointBorderColor: 'rgba(247, 145, 35, 1)',
                pointRadius: 4,
                pointHoverRadius: 8,
                datalabels: {
                    display: function(context) {
                        const dataset = context.dataset.data;
                        const value = dataset[context.dataIndex];
                        return value === max(dataset);
                    }, // 차트에 최대값만 보이도록 설정
                    color: 'rgba(247, 145, 35, 1)',
                    align: 'top',
                    font: {
                        size: 18,
                        weight: 'bold'
                    }
                }
            },
            {
                label: `${selectedDateB.toISOString().split("T")[0]}`, // B 날짜로 label 설정
                data: dataB.length > 0 ? dataB.map(item => item.hourly_attendees) : [0],
                borderColor: 'rgba(54, 162, 235, 1)',
                backgroundColor: 'rgba(54, 162, 235, 0.2)', // 채우기 색상
                borderWidth: 2,
                pointBackgroundColor: 'rgba(255, 255, 255, 1)',
                pointBorderColor: 'rgba(54, 162, 235, 1)',
                pointRadius: 4,
                pointHoverRadius: 8,
                datalabels: {
                    display: function(context) {
                        const dataset = context.dataset.data;
                        const value = dataset[context.dataIndex];
                        return value === max(dataset);
                    }, // 차트에 최대값만 보이도록 설정
                    color: 'rgba(54, 162, 235, 1)',
                    align: 'top',
                    font: {
                        size: 18,
                        weight: 'bold'
                    }
                }
            },
            {
                label: `${selectedDateC.toISOString().split("T")[0]}`, // C 날짜로 label 설정
                data: dataC.length > 0 ? dataC.map(item => item.hourly_attendees) : [0],
                borderColor: 'rgba(16, 148, 16, 1)',
                backgroundColor: 'rgba(16, 148, 16, 0.2)', // 채우기 색상
                borderWidth: 2,
                pointBackgroundColor: 'rgba(255, 255, 255, 1)',
                pointBorderColor: 'rgba(16, 148, 16, 1)',
                pointRadius: 4,
                pointHoverRadius: 8,
                datalabels: {
                    display: function(context) {
                        const dataset = context.dataset.data;
                        const value = dataset[context.dataIndex];
                        return value === max(dataset);
                    }, // 차트에 최대값만 보이도록 설정
                    color: 'rgba(16, 148, 16, 1)',
                    align: 'top',
                    font: {
                        size: 18,
                        weight: 'bold'
                    }
                }
            },
            {
                label: `${selectedDateD.toISOString().split("T")[0]}`, // D 날짜로 label 설정
                data: dataD.length > 0 ? dataD.map(item => item.hourly_attendees) : [0],
                borderColor: 'rgba(241, 121, 203, 1)',
                backgroundColor: 'rgba(241, 121, 203, 0.2)', // 채우기 색상
                borderWidth: 2,
                pointBackgroundColor: 'rgba(255, 255, 255, 1)',
                pointBorderColor: 'rgba(241, 121, 203, 1)',
                pointRadius: 4,
                pointHoverRadius: 8,
                datalabels: {
                    display: function(context) {
                        const dataset = context.dataset.data;
                        const value = dataset[context.dataIndex];
                        return value === max(dataset);
                    }, // 차트에 최대값만 보이도록 설정
                    color: 'rgba(241, 121, 203, 1)',
                    align: 'top',
                    font: {
                        size: 18,
                        weight: 'bold'
                    }
                }
            }
        ]
    };

    // 차트 옵션
    const options = {
        scales: {
            x: {
                type: 'category',
                time: {
                    unit: 'hour',
                    tooltipFormat: 'HH:mm',
                    displayFormats: {
                        hour: 'HH:mm'
                    }
                },
                offset: true
            },
            y: {
                beginAtZero: true,
                ticks: {
                    callback: function(value) {
                        return value.toLocaleString(); // 숫자를 천 단위로 포맷팅
                    }
                },
                afterDataLimits: (axis) => {
                    const max = axis.max;
                    axis.max = max * 1.1; // 현재 데이터의 최대값에서 10%를 더함
                }
            }
        },
        plugins: {
            tooltip: {
                enabled: true,
                backgroundColor: "rgba(0,0,0,0.7)",
                titleColor: "#fff",
                bodyColor: "#fff",
                borderColor: "rgba(75, 192, 192, 1)",
                borderWidth: 1,
                callbacks: {
                    // 첫째줄: 인원 수만 표시 (명 추가)
                    title: function(context) {
                        if (context && context[0] && context[0].raw !== undefined) {
                            const attendees = context[0].raw; // 인원수 가져오기
                            return attendees.toLocaleString() + '명'; // 인원 수 뒤에 '명' 추가
                        }
                        return ''; // 데이터가 없을 경우 빈 문자열 반환
                    },
                    // 둘째줄: 선택한 날짜와 시간 표시 (selectedDateA 또는 selectedDateB 사용)
                    label: function(context) {
                        const date = context.dataset.label === selectedDateA.toISOString().split('T')[0]
                            ? selectedDateA
                            : selectedDateB;
    
                        const dateString = date.toISOString().split('T')[0]; // 날짜 (YYYY-MM-DD)
                        const time = context.label; // 시간 (HH:mm)
                        return `${dateString} ${time}`; // 날짜와 시간 함께 표시
                    }
                }
            },
            datalabels: {
                display: true,
                align: 'top',
                color: function(context) {
                    // datasetIndex에 따라 4가지 색상을 순차적으로 사용
                    switch (context.datasetIndex) {
                        case 0:
                            return 'rgba(247, 145, 35, 1)'; // 첫 번째 데이터셋 (A 날짜)
                        case 1:
                            return 'rgba(54, 162, 235, 1)'; // 두 번째 데이터셋 (B 날짜)
                        case 2:
                            return 'rgba(24, 231, 76, 1)';  // 세 번째 데이터셋 (C 날짜)
                        case 3:
                            return 'rgba(241, 121, 203, 1)'; // 네 번째 데이터셋 (D 날짜)
                        default:
                            return 'rgba(0, 0, 0, 1)'; // 기본 색상 (이외의 경우)
                    }
                },
                font: {
                    size: 12,
                    weight: 'bold'
                },
                formatter: (value) => {
                    return value.toLocaleString(); // 데이터 값을 천 단위로 포맷
                }
            }
        }
    };
    

    return (
        <div className={styles.chartContainer}>
            <div className={`${styles.datePickerContainer} ${styles.chart}`}>
                <div className={styles.datePickerWrapper1}>
                    <label>1. 날짜 선택: </label>
                    <DatePicker
                        selected={selectedDateA}
                        onChange={(date) => setSelectedDateA(date)}
                        dateFormat="yyyy-MM-dd"
                        className={styles.custom_datepicker__input}
                    />
                </div>
                <div className={styles.datePickerWrapper2}>
                    <label>2. 날짜 선택: </label>
                    <DatePicker
                        selected={selectedDateB}
                        onChange={(date) => setSelectedDateB(date)}
                        dateFormat="yyyy-MM-dd"
                        className={styles.custom_datepicker__input}
                    />
                </div>
                <div className={styles.datePickerWrapper3}>
                    <label>3. 날짜 선택: </label>
                    <DatePicker
                        selected={selectedDateC}
                        onChange={(date) => setSelectedDateC(date)}
                        dateFormat="yyyy-MM-dd"
                        className={styles.custom_datepicker__input}
                    />
                </div>
                <div className={styles.datePickerWrapper4}>
                    <label>4. 날짜 선택: </label>
                    <DatePicker
                        selected={selectedDateD}
                        onChange={(date) => setSelectedDateD(date)}
                        dateFormat="yyyy-MM-dd"
                        className={styles.custom_datepicker__input}
                    />
                </div>
                {dataA.length > 0 || dataB.length > 0 ? (
                    <Line data={chartData} options={options} className={styles.chart}/>
                ) : (
                    <div>데이터가 없습니다.</div>
                )}
            </div>
        </div>
    );
};

export default ChartBCamera;
