import React, { useState } from 'react';
import styles from "./PasswordPrompt.module.css";

const PasswordPrompt = ({ onClose, onSubmit, onCancel }) => {
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = () => {
        if (password === 'injefestival') {
            alert("비밀번호가 일치합니다!");
            onSubmit(); // 데이터 불러오기
            onClose();  // 모달 닫기
        } else {
            setErrorMessage("비밀번호가 일치하지 않습니다.");  // 에러 메시지 설정
            setPassword("");  // 비밀번호 입력창 초기화
        }
    };

    const handleCancel = () => {
        // 취소하면 모달 닫고 페이지 새로고침
        onClose();  // 모달을 닫고
        window.location.reload();  // 페이지를 새로고침
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(); // Enter 키가 눌리면 handleSubmit 함수 실행
        }
    };

    const handleBackgroundClick = (e) => {
        e.stopPropagation();  // 모달 외부 클릭 시 모달을 닫지 않음
    };

    const handleModalContentClick = (e) => {
        e.stopPropagation(); // 모달 내부 클릭 시 이벤트 전파 방지
    };

    return (
        <div className={styles.modal} onClick={handleBackgroundClick}>
            <div className={styles.modalContent} onClick={handleModalContentClick}>
                <h2>비밀번호를 입력하세요</h2>
                <input
                    type="password"
                    className={styles.passwordInput}
                    value={password}
                    onChange={handlePasswordChange}
                    onKeyDown={handleKeyDown}  // Enter 키 처리
                    placeholder="비밀번호 입력"
                />
                {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
                <div className={styles.buttonGroup}>
                    <button className={styles.passwordPromptBtn} onClick={handleSubmit}>확인</button>
                    <button className={styles.cancelBtn} onClick={handleCancel}>취소</button> {/* 취소 버튼 추가 */}
                </div>
            </div>
        </div>
    );
};

export default PasswordPrompt;
