import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

// 응답을 처리하는 함수
const handleResponse = (response) => {
    if (response.status === 200 || response.status === 201) {
        return response.data;  // 상태 코드가 200이나 201일 때만 데이터를 반환
    } else {
        throw new Error(`Unexpected status code: ${response.status}`);
    }
};

export const getTotalPersonsDaily = async (startDate, endDate) => {
    try {
        const response = await api.get("/becon/total-persons-daily", { params: { start_date: startDate, end_date: endDate } });
        console.log("Received API response:", response.data);
        return handleResponse(response);  // 응답 처리 함수 호출
    } catch (error) {
        console.error("Error fetching total persons daily:", error);
        throw error;  // 에러를 상위로 던져서 추가 처리 가능
    }
};

export const getHourlyPersons = async (date) => {
    console.log("Sending request for date:", date);
    try {
        const response = await api.get("/becon/hourly-persons", { params: { date } });
        console.log("Received API response:", response.data);
        return handleResponse(response);  // 응답 처리 함수 호출
    } catch (error) {
        console.error("Error fetching hourly persons:", error);
        throw error;
    }
};

export const getTotalPersonsDailyUnique = async (startDate, endDate) => {
    try {
        const response = await api.get("/becon/total-persons-daily-unique", { params: { start_date: startDate, end_date: endDate } });
        console.log("Received API response:", response.data);
        return handleResponse(response);  // 응답 처리 함수 호출
    } catch (error) {
        console.error("Error fetching total persons daily:", error);
        throw error;  // 에러를 상위로 던져서 추가 처리 가능
    }
};

export const getHourlyPersonsUnique = async (date) => {
    console.log("Sending request for date:", date);
    try {
        const response = await api.get("/becon/hourly-persons-unique", { params: { date } });
        console.log("Received API response:", response.data);
        return handleResponse(response);  // 응답 처리 함수 호출
    } catch (error) {
        console.error("Error fetching hourly persons:", error);
        throw error;
    }
};

export const getSectionTotalPersons = async (startDate, endDate) => {
    try {
        const response = await api.get("/becon/section-total-persons", { params: { start_date: startDate, end_date: endDate } });
        console.log("Received API response:", response.data);
        return handleResponse(response).data.section_total_persons;  // 내부 data 접근 후 반환
    } catch (error) {
        console.error("Error fetching section total persons:", error);
        throw error;
    }
};

export const getSectionStayTime = async (date) => {
    try {
        const response = await api.get("/becon/section-stay-time", { params: { date } });
        console.log("Received API response:", response.data);
        return handleResponse(response).data.section_stay_time;  // 내부 data 접근 후 반환
    } catch (error) {
        console.error("Error fetching section stay time:", error);
        throw error;
    }
};

export const getSectionTotalPersonsUnique = async (startDate, endDate) => {
    try {
        const response = await api.get("/becon/section-total-persons-unique", { params: { start_date: startDate, end_date: endDate } });
        console.log("Received API response:", response.data);
        return handleResponse(response).data.section_total_persons;  // 내부 data 접근 후 반환
    } catch (error) {
        console.error("Error fetching section total persons:", error);
        throw error;
    }
};

export const getSectionStayTimeUnique = async (date) => {
    try {
        const response = await api.get("/becon/section-stay-time-unique", { params: { date } });
        console.log("Received API response:", response.data);
        return handleResponse(response).data.section_stay_time;  // 내부 data 접근 후 반환
    } catch (error) {
        console.error("Error fetching section stay time:", error);
        throw error;
    }
};

export const getTotalPersonsDailyCamera = async (startDate, endDate) => {
    try {
        const response = await api.get("/camera/total-persons-daily", { params: { start_date: startDate, end_date: endDate } });
        console.log("Received API response:", response.data);
        return handleResponse(response);  // 응답 처리 함수 호출
    } catch (error) {
        console.error("Error fetching total persons daily:", error);
        throw error;  // 에러를 상위로 던져서 추가 처리 가능
    }
};

export const getHourlyPersonsCamera = async (date) => {
    console.log("Sending request for date:", date);
    try {
        const response = await api.get("/camera/hourly-persons", { params: { date } });
        console.log("Received API response:", response.data);
        return handleResponse(response);  // 응답 처리 함수 호출
    } catch (error) {
        console.error("Error fetching hourly persons:", error);
        throw error;
    }
};

export const getDailyAccumulatedPersonsCamera = async (date) => {
    console.log("Sending request for date:", date);
    try {
        const response = await api.get("/camera/daily-accumulated-persons", { params: { date } });
        console.log("Received API response:", response.data);
        return handleResponse(response);  // 응답 처리 함수 호출
    } catch (error) {
        console.error("Error fetching hourly persons:", error);
        throw error;
    }
};

export const getTableDataSingleDay = async (date) => {
    console.log("Sending request for date:", date);
    try {
        const response = await api.get("/camera/get-table-data-single-day", { params: { date } });
        console.log("Received API response:", response.data);
        return handleResponse(response);  // 응답 처리 함수 호출
    } catch (error) {
        console.error("Error fetching hourly persons:", error);
        throw error;
    }
};

// 인원 수 업데이트 API 호출 함수
export const updateAttendeesCount = async (id, updatedCount) => {
    console.log(`Calling updateAttendeesCount API to update attendees count with ID: ${id} and count: ${updatedCount}`);

    try {
        // total_attendees 대신 attendee_count로 값을 보내도록 수정
        const response = await api.put(`/camera/update-attendees-count/${id}`, {
            attendee_count: updatedCount,  // 필드 이름 수정
        });
        console.log("Received updateAttendeesCount API response:", response.data);
        return response.data;
    } catch (error) {
        console.error("Error calling updateAttendeesCount API:", error);
        throw error;
    }
};

// 파일 업로드 API 호출 함수
export const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
        // 파일을 전송하는 POST 요청
        const response = await api.post("/upload", formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        console.log("File upload response:", response.data);
        return response.data;
    } catch (error) {
        console.error("Error uploading file:", error);
        throw error;
    }
};
